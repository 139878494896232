import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import cogoToast from "cogo-toast";
import {getTransactionReport, transaction} from "../../../api/app";
import moment from "moment";
import DateRangePicker from "../../../components/common/DatePicker";
import formatNumber from "../../../utils/formatNumber";

let currentDateRange = null;

const Transaction = () => {

    document.title = "Transaction";

    const token = localStorage.getItem('jwtToken');
    const [selectedDate, setSelectedDate] = useState(false);
    const [currDateRange, setCurrDateRange] = useState(null);
    const [reset, setReset] = useState(new Date().getTime());

    useEffect(() => {
        let data = currentDateRange !== null ? {
            start_date: currentDateRange.start,
            end_date: currentDateRange.end
        } : {}

        getTransactionReport(data).then((response) => {
            if(!response.error) {
                $('#total-transaction').text(formatNumber(response.payload.total_transaction));
                $('#total-transaction-month').text(formatNumber(response.payload.total_transaction_month));
                $('#total-transaction-day').text(formatNumber(response.payload.total_transaction_day));
                $('.current-date').text(response.payload.current_date);
            }
        });
    }, [currentDateRange, reset]);

    useEffect(() => {
        let table = $('#td-transaction').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Transaction Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "waste",
                        fieldname: "name",
                        pageSize: data.start,
                        filter: currentDateRange !== null ? {
                            status: { $in: ["paid", "expired"] },
                            "paid_date.str":
                                {
                                    $gte: currentDateRange.start,
                                    $lte: currentDateRange.end
                                }
                        } : {status: { $in: ["paid", "expired"] }},
                        format: 'json',
                        sort: {"paid_date.str": -1},
                        populate: [{
                                path: 'transaction',
                                model: 'transaction'
                            },
                            {
                                path: 'user',
                                model: 'user',
                            },
                            {
                                path: 'address.property',
                                model: 'property',
                            },
                            {
                                path: 'product',
                                model: 'product'
                            }],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.transaction?.txn_number.toUpperCase();
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `K${data.transaction?.amount}`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "renew-subscription" : `<span class="text-capitalize">Subscription - Renew</span>`,
                            "credit-subscription" : `<span class="text-capitalize">Subscription - Credit</span>`,
                            "subscription" : `<span class="text-capitalize">Subscription - New</span>`
                        }[data?.transaction?.metadata?.type] || `<span class="text-capitalize">Subscription<span class="text-danger">(Unknown)</span></span>`;
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<span class="badge badge-success tx-white">Paid</span>`
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return moment(data.paid_date.str).format("Do MMM, YYYY - HH:mm");
                    },
                    "targets": 5
                }
            ]
        });

        $('#td-transaction tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>User:</td>' +
                '<td>'+
                `<a href="#/user/${d.raw.user._id}">${d.raw.user.mobile_number} - ${capitalize(d.raw.user.first_name)} ${capitalize(d.raw.user.last_name)}</a>` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Waste:</td>' +
                '<td>'+ capitalize(d.raw.type) +'</td>' +
                '</tr>' +
                '<td>Payment Method:</td>' +
                '<td>'+ capitalize(d.raw.transaction.gateway) +'</td>' +
                '</tr>' +
                '</table>'
            );
        }

        table.on('click', '.view-transaction', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            //Transaction Detail
            $("#tx-transaction-number").text(extract_td.raw.transaction.txn_number.toUpperCase());
            $("#tx-gateway").text(extract_td.raw.transaction.gateway);
            $("#tx-status").html({
                "paid" : `<span class="badge badge-success tx-white">${capitalize(extract_td.raw.status)}</span>`,
                "pending" : `<span class="badge badge-secondary tx-white">${capitalize(extract_td.raw.status)}</span>`,
                "failed" : `<span class="badge badge-danger tx-white">${capitalize(extract_td.raw.status)}</span>`
            }[extract_td.raw.status]);
            $("#tx-date").text(moment(extract_td.raw.date.iso).format("Do MMM, YYYY"));
            $("#tx-amount").text(extract_td.raw.transaction.amount);

            //User Detail
            $("#tx-full-name").text(`${extract_td.raw.user.first_name} ${extract_td.raw.user.last_name}`);
            $("#tx-mobile-number").text(extract_td.raw.user.mobile_number);
            $("#tx-gender").text(extract_td.raw.user.gender);
            $("#tx-address").text(extract_td.raw.user.addresses.length);
            $("#tx-joined-date").text(moment(extract_td.raw.date.iso).format("Do MMM, YYYY"));

            //Waste Detail
            $("#tx-product").text(extract_td.raw.product.type);
            $("#tx-property").text(extract_td.raw.address.property.type);
            $("#tx-collector").text(extract_td.raw.collector.name);
            $("#tx-location").text(`${extract_td.raw.address.unit_number} ${extract_td.raw.address.meta.street_address}`);
            $("#tx-period").text(moment(extract_td.raw.due_date.iso).format("Do MMM, YYYY"));

            $("#modalReport").modal({ backdrop: 'static', focus: false, show: true });
        });

        table.on('click', '.delete-transaction', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${extract_td.raw.txn_number.toUpperCase()}?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                transaction("delete", data).then((result) => {
                    if(!result.error) {
                        hide();
                        reloadTable();
                        cogoToast.success("Transaction successfully removed.", {position: "top-right"});
                    } else {
                        hide();
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        return () => {
            table.destroy();
            currentDateRange = null;
        }

    }, []);


    const reloadTable = () => {
        setReset(new Date().getTime());
        $('#td-transaction').DataTable().ajax.reload(null, false);
    };

    const handleDateRangeSelect = (range) => {
        const formattedStartDate = $.datepicker.formatDate('dd/mm/yy', range.startDate);
        const formattedEndDate = $.datepicker.formatDate('dd/mm/yy', range.endDate);

        currentDateRange = {
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        };

        setCurrDateRange({
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        });

        setSelectedDate(true);

        reloadTable();
    };

    const refreshTable = (e) => {
        e.preventDefault();
        setSelectedDate(false);
        setCurrDateRange(null);
        currentDateRange = null;
        setReset(new Date().getTime());
        $('#td-transaction').DataTable().ajax.reload(null, false);
    }

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Transactions</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Transactions</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Transactions</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="total-transaction">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Amount of Transactions</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span>Since 6th August, 2024</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">{currDateRange ? "Total Cumulative Transactions" : "Total Transactions Month"}</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="total-transaction-month">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Transaction Amount</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span className="current-date">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">{currDateRange ? "Total Average Transactions" : "Total Transactions Day"}</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="total-transaction-day">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Transaction Amount</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span className="current-date">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Transactions</h6>
                                <div className="d-flex tx-18">
                                    <div className="tx-12 tx-color-03 align-items-center d-none d-sm-flex mg-r-15">
                                        <a href="javascript:void(0)" className="link-01 tx-spacing-1 tx-rubik d-flex align-items-center" onClick={() => $(`#dateRangePicker`).datepicker('show')}>
                                            <DateRangePicker id="dateRangePicker" onDateRangeSelect={handleDateRangeSelect} />
                                            {selectedDate ?
                                                <div>
                                                    <span id="start_date">{moment(currDateRange?.start).format('DD/MM/YYYY')}</span>
                                                    <span className="mg-x-10">to</span>
                                                    <span id="end_date">{moment(currDateRange?.end).format('DD/MM/YYYY')}</span>
                                                </div> : <span>Select Date Range</span>}
                                            <ion-icon name="chevron-down-outline" aria-label="chevron down outline" role="img" className="md hydrated"></ion-icon>
                                        </a>
                                    </div>
                                    <a href="#" onClick={refreshTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-transaction" className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">TXN Number</th>
                                        <th className="wd-20p">Amount</th>
                                        <th className="wd-20p">Services</th>
                                        <th className="wd-20p">Status</th>
                                        <th className="wd-20p">Date</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                            <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalReport" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body pd-sm-b-40 pd-sm-x-30">

                            <div className="media-body">
                                <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a>
                                <h4 className="tx-18 tx-sm-20 mg-b-10">Transaction</h4>
                            </div>

                            <ul className="nav nav-line nav-fill" id="myTab5" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="info-tab5" data-toggle="tab" href="#info" role="tab" aria-controls="home" aria-selected="true">Payment</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="customer-tab5" data-toggle="tab" href="#customer" role="tab" aria-controls="profile" aria-selected="false">Customer</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="waste-tab5" data-toggle="tab" href="#waste" role="tab" aria-controls="contact" aria-selected="false">Waste</a>
                                </li>
                            </ul>

                            <div className="tab-content mg-t-20" id="myTabContent5">
                                <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab5">
                                    <p><strong>Transaction #:</strong> <span id="tx-transaction-number">---</span></p>
                                    <p><strong>Gateway:</strong> <span id="tx-gateway">---</span></p>
                                    <p><strong>Amount:</strong> ZMW<span id="tx-amount">---</span></p>
                                    <p><strong>Status:</strong> <span id="tx-status">---</span></p>
                                    <p><strong>Report Date:</strong> <span id="tx-date">---</span></p>
                                </div>
                                <div className="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab5">
                                    <p><strong>Full Name:</strong> <span id="tx-full-name" className="text-capitalize">---</span></p>
                                    <p><strong>Mobile Number:</strong> <span id="tx-mobile-number">---</span></p>
                                    <p><strong>Gender:</strong> <span id="tx-gender" className="text-capitalize">---</span></p>
                                    <p><strong># of Addresses:</strong> <span id="tx-address">---</span></p>
                                    <p><strong>Joined Date:</strong> <span id="tx-joined-date">---</span></p>
                                </div>
                                <div className="tab-pane fade" id="waste" role="tabpanel" aria-labelledby="waste-tab5">
                                    <p><strong>Product:</strong> <span id="tx-product" className="text-capitalize">---</span></p>
                                    <p><strong>Property:</strong> <span id="tx-property" className="text-capitalize">---</span></p>
                                    <p><strong>Collector:</strong> <span id="tx-collector">---</span></p>
                                    <p><strong>Location:</strong> <span id="tx-location">---</span></p>
                                    <p><strong>Expiry Date:</strong> <span id="tx-period">---</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Transaction;
